.bar-chart-resp {
    width: 90%;
    height: 500px;
}

.bar-chart-resp2 {
    width: 60%;
    height: 500px;
}

@media (min-width: 511px) and (max-width: 920px) {
    .bar-chart-resp, .bar-chart-resp2 {
        width: 100% !important;
        height: 100% !important;
    }
}

@media (min-width: 210px) and (max-width: 510px) {
    .bar-chart-resp, .bar-chart-resp2 {
        width: 100% !important;
        height: 100% !important;
    }
}